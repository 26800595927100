export const paths = {
  projects: '/projects',
  projectDetails: `/project/*/*/details`,
};

export const getProjectId = () => {
  const pathName = window.location.href;
  const paths = pathName.split('/');
  return paths[5];
};

export const getShieldId = () => {
  const pathName = window.location.href;
  const paths = pathName.split('/');
  return paths[6];
};

export const getEnv = () => {
  if (window.location.href.includes('https://gamma.pi-ui.nazir.amazon.dev')) {
    return 'gamma';
  } else {
    return 'beta';
  }
};

export const SidebarItems = [
  {
    items: [
      {
        value: 'Cost Estimate',
        label: 'Cost Estimate',
        url: 'Models',
      },
      {
        value: 'Funding',
        label: 'Funding',
        url: 'Funding',
      },
    ],
    label: 'Project Name',
    value: 'recent',
    url: 'recent',
  },
];

export const ProjectDetailsFields = [
  {
    title: 'Project Manager',
    label: 'projectManager',
  },
  {
    title: 'Project ID',
    label: 'projectID',
  },
  {
    title: 'Region',
    label: 'region',
  },
  {
    title: 'Region',
    label: 'region',
  },
  {
    title: 'Business Unit',
    label: 'businessUnit',
  },
  {
    title: 'Business Type',
    label: 'businessType',
  },
  {
    title: 'Project Type',
    label: 'projectType',
  },
  {
    title: 'Building Type',
    label: 'buildingType',
  },
  {
    title: 'Facility Type',
    label: 'facilityType',
  },
  {
    title: 'Launch Year',
    label: 'launchYear',
  },
  {
    title: 'Desing Cost Template',
    label: 'template',
  },
  {
    title: 'COA',
    label: 'coa',
  },
];

export const CapacityFields = [
  {
    title: 'Cube',
    label: 'cube',
  },
  {
    title: 'Square Footage',
    label: 'squareFootage',
  },
  {
    title: 'Throughout',
    label: 'throughout',
  },
];

export const SiteDetailsFields = [
  {
    title: 'Site ID',
    label: 'siteID',
  },
  {
    title: 'Site Name',
    label: 'siteName',
  },
  {
    title: 'POR Site Code',
    label: 'porSiteCode',
  },
  {
    title: 'Site Launch Year',
    label: 'siteLaunchYear',
  },
  {
    title: 'POR Year',
    label: 'porYear',
  },
  {
    title: 'Site Status',
    label: 'siteStatus',
  },
];

export const GeoSpecialDetailsFields = [
  {
    title: 'Address',
    label: 'address',
  },
  {
    title: 'City',
    label: 'city',
  },
  {
    title: 'State',
    label: 'state',
  },
  {
    title: 'Zip Code',
    label: 'zipCode',
  },
  {
    title: 'Country',
    label: 'country',
  },
  {
    title: 'Latitude',
    label: 'latitude',
  },
  {
    title: 'Longitude',
    label: 'longitude',
  },
];
