import React from 'react';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import {Table} from '@amzn/nazare-websites-shared-assets';

const Projects = (props: {tableData: any}) => {
  return (
    <Column width="100%" spacingInset="300">
      <Row width="100%" widths="fill" spacingInset="100">
        <Text type="h600">My Projects</Text>
      </Row>
      <Row width="100%" widths="fill" spacingInset="100">
        <Table {...props.tableData}></Table>
      </Row>
    </Column>
  );
};

export default Projects;
