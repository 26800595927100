import axios from 'axios';
import jwtDecode, {JwtPayload} from 'jwt-decode';
import * as qs from 'query-string';
import APP_CONFIG from './appConfig';

export const MIDWAY_URL = 'https://midway-auth.amazon.com/SSO';

interface MidwayRequestParams {
  scope: string;
  response_type: string;
  client_id: string;
  redirect_uri: string;
  nonce: string;
  sentry_handler_version?: string;
}

function generateNonce() {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
}

function buildMidwayRequestUrl(shouldRedirect = false): string {
  const params: MidwayRequestParams = {
    scope: 'openid',
    response_type: 'id_token',
    client_id: APP_CONFIG.clientId,
    redirect_uri: window.location.origin,
    nonce: generateNonce(),
  };
  if (!shouldRedirect) {
    params.sentry_handler_version = 'MidwayNginxModule-1.3-1';
  }
  const baseUrl = shouldRedirect ? `${MIDWAY_URL}/redirect?` : `${MIDWAY_URL}?`;
  return baseUrl + qs.stringify(params);
}

async function fetchToken(): Promise<any | undefined> {
  try {
    const midwayRequestUrl = buildMidwayRequestUrl();
    const response = await axios(midwayRequestUrl, {withCredentials: true});
    return response.data;
  } catch (error) {
    localStorage.setItem('redirect_url', window.location.pathname);
    window.location.href = await buildMidwayRequestUrl(true);
    console.error(
      `Failed to fetch web identity token from Midway, Error: ${error}`
    );
    return null;
  }
}

export async function getWebIdentityTokenOrRedirect(): Promise<
  any | undefined
> {
  try {
    return await fetchToken();
  } catch (error: any) {
    if (error.message === 'Unauthorized') {
      window.location.href = buildMidwayRequestUrl(true);
    } else {
      console.error(
        `Failed to fetch token from Midway with redirect. Error: ${error}`
      );
    }
    return undefined;
  }
}

export function getUserAlias(webIdentityToken: any): string | undefined {
  try {
    const decoded: JwtPayload = jwtDecode(webIdentityToken);
    return decoded.sub;
  } catch (error) {
    console.error('Error when retrieving user alias.', error);
    return undefined;
  }
}

export function getExpirationTime(webIdentityToken: any): number | undefined {
  try {
    const decoded: JwtPayload = jwtDecode(webIdentityToken);
    return decoded.exp;
  } catch (error) {
    console.error('Error when getting expiration time', error);
    return undefined;
  }
}
