import React, {useEffect, useState} from 'react';

import history from 'src/common/history';
import {clone} from 'lodash';
import {SidebarItems} from 'src/common/constants';
import Projects from 'src/components/Projects/Projects';
import LayoutComponent from 'src/components/common/Layout/LayoutComponent';
import {useProjectsData} from 'src/common/projectIdentityQuery';
import Row from '@amzn/meridian/row';
import Link from '@amzn/meridian/link';
import {ProjectData} from 'src/common/dataType';

const tableData = {
  editMode: true,
  actionBar: {
    show: false,
    render: () => {},
  },
  header: {
    fixed: true,
    columnArr: [
      {
        label: 'project_ID',
        name: 'Project ID',
        editable: false,
      },
      {
        label: 'projectName',
        name: 'Project Name',
        editable: false,
      },
      {
        label: 'city',
        name: 'City',
        editable: false,
      },
      {
        label: 'state',
        name: 'State',
        editable: false,
      },
      {
        label: 'country',
        name: 'Country',
        editable: false,
      },
      {
        label: 'facilityType',
        name: 'Facility Type',
        editable: false,
      },
      {
        label: 'buildingType',
        name: 'Building Type',
        editable: false,
      },
      {
        label: 'launchYear',
        name: 'Launch Year',
        editable: false,
      },
      {
        label: 'details',
        name: 'Details',
        render: function (data: {
          cellData: {
            value: ProjectData['project'];
            label: string;
          };
        }) {
          return (
            <Link
              href={`/#/project/${data.cellData.value?.identifier?.id}/${data.cellData.value?.identifier?.source}/details`}
            >
              {data.cellData.label}
            </Link>
          );
        },

        editable: false,
      },
    ],
  },
  body: {
    data: [],
    isFetching: true,
    showLoader: function () {
      return (
        <Row
          className="loading-container"
          width="100%"
          widths="fill"
          spacingInset={'600 100'}
          alignmentHorizontal="center"
        >
          Fetching...
        </Row>
      );
    },
    showEmptyTableMessage: function () {
      return (
        <Row
          className="empty-table-container"
          width="100%"
          widths="fill"
          spacingInset={'600 100'}
          alignmentHorizontal="center"
        >
          No Data
        </Row>
      );
    },
  },
  children: '',
};

const ProjectsContainer = () => {
  const [projects, setProjects] = useState(tableData);
  const {isLoading, data} = useProjectsData();

  useEffect(() => {
    let rowData = [];

    if (!isLoading && data?.projects) {
      rowData = data.projects.map(
        (project: ProjectData['project'], projectIndex: number) => ({
          id: projectIndex,
          project_ID: project?.identifier?.id,
          projectName: project?.name,
          city: project?.locationDetails?.siteEntity?.siteDetails?.address
            ?.city,
          state:
            project?.locationDetails?.siteEntity?.siteDetails?.address?.state,
          country:
            project?.locationDetails?.siteEntity?.siteDetails?.address?.country,
          facilityType:
            project?.projectMetadata?.facilityDetails?.gesFacility
              ?.facilityType,
          buildingType: project?.projectMetadata?.buildingType,
          launchYear: project?.timelineDetails?.expectedCompletionYear?.year,
          details: {label: 'details', value: project},
        })
      );
      projects.body.data = rowData;
      const updatedProjects = clone(projects);
      setProjects(updatedProjects);
    }
  }, [data]);

  return (
    <LayoutComponent
      onClickSidebar={(label: string) => console.log(label)}
      onClickHeader={() => {}}
      sidebarItems={[]}
    >
      <Projects tableData={projects} />
    </LayoutComponent>
  );
};

export default ProjectsContainer;
