import {executeRequest} from './auth/signRequest';
import APP_CONFIG from './auth/appConfig';
import {getEnv} from './constants';

const envName = getEnv();

enum MethodTypes {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
}

interface APIRequest {
  path: string;
  method: MethodTypes;
  data?: any; // request body
}

const API_URLS = {
  getProjects: () => `/${envName}/v1/getAllProjects`,
  getProjectDetails: () => `/${envName}/v1/getProjectById`,
};

export const getProjects = async () => {
  const request: APIRequest = {
    path: API_URLS.getProjects(),
    method: MethodTypes.GET,
  };
  const response = await executeRequest(
    request,
    APP_CONFIG.projectApiUrl,
    'PROJECT_IDENTITY'
  );
  return response?.data;
};

export const getProjectDetails = async (payload: any) => {
  const request: APIRequest = {
    path: API_URLS.getProjectDetails(),
    method: MethodTypes.POST,
    data: payload,
  };
  const response = await executeRequest(
    request,
    APP_CONFIG.projectApiUrl,
    'PROJECT_IDENTITY'
  );
  return response?.data;
};

export default {
  getProjects,
  getProjectDetails,
};
