// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react';
import Table, {TableRow, TableCell} from '@amzn/meridian/table';

const headerColumnWidth = 250;

const HorizontalTableComponent = ({items}) => {
  return (
    <Table showDividers={true}>
      {items &&
        items.map(row => (
          <TableRow highlightOnHover={false}>
            <TableCell highlight={true} width={headerColumnWidth}>
              {row.title}
            </TableCell>
            <TableCell>{row.value}</TableCell>
          </TableRow>
        ))}
    </Table>
  );
};

export default HorizontalTableComponent;
