import React from 'react';
import {Router, Route, Switch} from 'react-router-dom';
import ProjectsContainer from 'src/containers/Projects/ProjectsContainers';
import ProjectDetailsContainers from 'src/containers/ProjectDetails/ProjectDetailsContainers';
import {paths} from 'src/common/constants';
import history from 'src/common/history';

function AppRouter() {
  return (
    <Router history={history}>
      <Switch>
        <Route path={paths.projects} component={ProjectsContainer} />
        <Route
          path={paths.projectDetails}
          component={ProjectDetailsContainers}
        />
      </Switch>
    </Router>
  );
}

export default AppRouter;
