import {useQuery} from 'react-query';
import ProjectIdentityApi from 'src/common/projectIdentityApi';

export const useProjectDetailsData = (projectId: string, shieldId: string) => {
  return useQuery(['project', projectId], () => {
    const requestPayload = {
      projectId: {id: projectId, source: shieldId},
    };
    return ProjectIdentityApi.getProjectDetails(requestPayload);
  });
};

export const useProjectsData = () =>
  useQuery('projects', () => {
    return ProjectIdentityApi.getProjects();
  });
