// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import SideMenu, {SideMenuLink} from '@amzn/meridian/side-menu';

const Sidebar = ({items, onClickSidebar}) => {
  const [hash, setHash] = useState('');
  const [openLinkHash, setOpenLinkHash] = useState(null);
  return (
    <SideMenu>
      {items.map(item => {
        return (
          <SideMenuLink
            key={item.value}
            href={item.url}
            selected={hash === item.value}
            open={item.items && openLinkHash === item.value}
            onClick={hash => {
              setHash(hash);
              // Toggle the link's open state when clicked multiple times
              setOpenLinkHash(openLinkHash === item.value ? null : hash);
            }}
          >
            {item.label}
            {item.items &&
              item.items.map(innterItem => {
                return (
                  <SideMenuLink
                    href={innterItem.url}
                    key={innterItem.value}
                    selected={hash === innterItem.value}
                    onClick={() => {
                      onClickSidebar(innterItem.label);
                      setHash(hash);
                    }}
                    // `onEscape` is called when a user presses the "escape" when focused
                    // on this nested link
                    onEscape={() => {
                      // Select the parent
                      setHash(innterItem.value);
                      // Close the group
                      setOpenLinkHash(null);
                    }}
                  >
                    {innterItem.label}
                  </SideMenuLink>
                );
              })}
          </SideMenuLink>
        );
      })}
    </SideMenu>
  );
};

Sidebar.propTypes = {
  items: PropTypes.array,
  onClickSidebar: PropTypes.func,
};

Sidebar.defaultProps = {};

export default Sidebar;
