import React from 'react';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import HorizontalTableComponent from 'src/components/common/HorizontalTable/HorizontalTableComponent';
import BreadcrumbComponent from 'src/components/common/Breadcrumb/BreadcrumbComponent';
import {ProjectData, HorizontalTableRowData} from 'src/common/dataType';

interface IRecipeProps {
  project: ProjectData['project'] | null;
  onClickBreadcrumb: Function;
}

const ProjectDetails = (props: IRecipeProps) => {
  let projectDetailsFieldsList: Array<HorizontalTableRowData> = [];
  let capacityFieldsList: Array<HorizontalTableRowData> = [];
  let siteDetailsFieldsList: Array<HorizontalTableRowData> = [];
  let geoSpecialDetailsFieldsList: Array<HorizontalTableRowData> = [];
  if (props.project) {
    projectDetailsFieldsList = [
      {
        title: 'Project ID',
        label: 'projectID',
        value: props.project?.identifier?.id,
      },
      {
        title: 'Region',
        label: 'region',
        value: props.project?.locationDetails?.regionEntity?.GESRegionEntity,
      },
      {
        title: 'Business Unit',
        label: 'businessUnit',
        value: props.project?.businessOwner?.GESBusinessEntity?.businessUnit,
      },
      {
        title: 'Business Type',
        label: 'businessType',
        value: props.project?.businessOwner?.GESBusinessEntity?.businessType,
      },
      {
        title: 'Project Type',
        label: 'projectType',
        value: props.project?.projectMetadata?.projectType,
      },
      {
        title: 'Building Type',
        label: 'buildingType',
        value: props.project?.projectMetadata?.buildingType,
      },
      {
        title: 'Facility Type',
        label: 'facilityType',
        value:
          props.project?.projectMetadata?.facilityDetails?.gesFacility
            ?.facilityType,
      },
      {
        title: 'Launch Year',
        label: 'launchYear',
        value: props.project?.timelineDetails?.expectedCompletionYear?.year,
      },
      {
        title: 'Design Type',
        label: 'template',
        value: props.project?.projectMetadata?.projectDesign?.designType,
      },
    ];

    capacityFieldsList = [
      {
        title: 'Cube',
        label: 'cube',
        value:
          props.project?.projectMetadata?.capacityMetrics?.cubicStorage?.value,
      },
      {
        title: 'Square Footage',
        label: 'squareFootage',
        value: props.project?.projectMetadata?.capacityMetrics?.area?.value,
      },
      {
        title: 'Throughput',
        label: 'throughput',
        value:
          props.project?.projectMetadata?.capacityMetrics?.throughput?.value,
      },
    ];

    siteDetailsFieldsList = [
      {
        title: 'Site ID',
        label: 'siteID',
        value:
          props.project?.locationDetails?.siteEntity?.siteDetails?.identifier
            ?.id,
      },
      {
        title: 'Site Name',
        label: 'siteName',
        value: props.project?.locationDetails?.siteEntity?.siteDetails?.name,
      },
      {
        title: 'COA Location Code',
        label: 'coa',
        value:
          props.project?.locationDetails?.siteEntity?.siteDetails?.siteMetadata
            ?.locationCode,
      },
      {
        title: 'Site Status',
        label: 'siteStatus',
        value:
          props.project?.locationDetails?.siteEntity?.siteDetails?.siteMetadata
            ?.status?.value,
      },
    ];

    geoSpecialDetailsFieldsList = [
      {
        title: 'Address',
        label: 'address',
        value:
          props.project?.locationDetails?.siteEntity?.siteDetails?.address
            ?.addressLine1,
      },
      {
        title: 'City',
        label: 'city',
        value:
          props.project?.locationDetails?.siteEntity?.siteDetails?.address
            ?.city,
      },
      {
        title: 'State',
        label: 'state',
        value:
          props.project?.locationDetails?.siteEntity?.siteDetails?.address
            ?.state,
      },
      {
        title: 'Zip Code',
        label: 'zipCode',
        value:
          props.project?.locationDetails?.siteEntity?.siteDetails?.address
            ?.zipCode,
      },
      {
        title: 'Country',
        label: 'country',
        value:
          props.project?.locationDetails?.siteEntity?.siteDetails?.address
            ?.country,
      },
      {
        title: 'Latitude',
        label: 'latitude',
        value:
          props.project?.locationDetails?.siteEntity?.siteDetails?.address
            ?.latitude,
      },
      {
        title: 'Longitude',
        label: 'longitude',
        value:
          props.project?.locationDetails?.siteEntity?.siteDetails?.address
            ?.longitude,
      },
    ];
  }

  return (
    <Column width="100%" spacingInset="300">
      <Row width="100%" widths="fill" spacingInset="100">
        <BreadcrumbComponent
          items={['Projects', props.project?.name]}
          onClickBreadcrumb={props.onClickBreadcrumb}
        />
      </Row>
      <Row width="100%" widths="fill" spacingInset="100">
        <Text type="h300">Project Details</Text>
      </Row>
      <Row width="100%" widths={['grid-6', 'grid-6']} spacingInset="100">
        <HorizontalTableComponent
          items={projectDetailsFieldsList.slice(0, 5)}
        />
        <HorizontalTableComponent
          items={projectDetailsFieldsList.slice(
            5,
            projectDetailsFieldsList.length
          )}
        />
      </Row>

      <Row width="100%" widths="fill" spacingInset="100">
        <Text type="h300">Capacity Details</Text>
      </Row>
      <Row width="100%" widths={['grid-6', 'grid-6']} spacingInset="100">
        <HorizontalTableComponent items={capacityFieldsList.slice(0, 2)} />
        <HorizontalTableComponent
          items={capacityFieldsList.slice(2, capacityFieldsList.length)}
        />
      </Row>

      <Row width="100%" widths="fill" spacingInset="100">
        <Text type="h300">Site Details</Text>
      </Row>
      <Row width="100%" widths={['grid-6', 'grid-6']} spacingInset="100">
        <HorizontalTableComponent items={siteDetailsFieldsList.slice(0, 2)} />
        <HorizontalTableComponent
          items={siteDetailsFieldsList.slice(2, siteDetailsFieldsList.length)}
        />
      </Row>

      <Row width="100%" widths="fill" spacingInset="100">
        <Text type="h300">Geo Special Details</Text>
      </Row>
      <Row width="100%" widths={['grid-6', 'grid-6']} spacingInset="100">
        <HorizontalTableComponent
          items={geoSpecialDetailsFieldsList.slice(0, 4)}
        />
        <HorizontalTableComponent
          items={geoSpecialDetailsFieldsList.slice(
            4,
            geoSpecialDetailsFieldsList.length
          )}
        />
      </Row>
    </Column>
  );
};

export default ProjectDetails;
