import axios, {AxiosResponse} from 'axios';
import aws4 from 'aws4';
import Url from 'url-parse';
import {getLatestCredentials} from './credentialsMethod';
import {getUserAlias, getWebIdentityTokenOrRedirect} from './midwayAuth';
import APP_CONFIG from './appConfig';

enum MethodTypes {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
}

interface APIRequest {
  path: string;
  method: MethodTypes;
  data?: any; // request body
}

export async function sign(
  originalRequest: APIRequest,
  requestUrl: string,
  module: string
): Promise<any | undefined> {
  const webIdentityToken = await getWebIdentityTokenOrRedirect();
  const userAlias = getUserAlias(webIdentityToken);

  const headerEntries = {'X-Forwarded-User': userAlias};
  const postRequestHeasers = originalRequest.data
    ? {
        'Content-Type': 'application/x-amz-json-1.0',
      }
    : {};

  const apiUrl = new Url(requestUrl);

  const requestToBeSigned = {
    host: apiUrl.host,
    path: `${originalRequest.path || ''}`,
    method: originalRequest.method || 'GET',
    headers: {...headerEntries, ...postRequestHeasers},
    body: JSON.stringify(originalRequest.data),
    timeout: 60000,
  };

  const credentials = getLatestCredentials(module);
  if (credentials !== undefined) {
    return aws4.sign(requestToBeSigned, credentials);
  }
  return undefined;
}

export async function executeRequest(
  request: APIRequest,
  requestUrl: string,
  module: string
): Promise<AxiosResponse | undefined> {
  const signedReq = await sign(request, requestUrl, module);

  if (signedReq !== undefined) {
    const signedRequest = {...signedReq};
    delete signedRequest.headers.host;
    delete signedRequest.headers['Content-Length'];
    signedRequest.url = `https://${signedReq.host}${signedRequest.path}`;
    signedRequest.data = signedRequest.body;
    try {
      return await axios(signedRequest);
    } catch (error) {
      console.error(`Failed to execute request. ${error}`);
      return undefined;
    }
  } else {
    console.error(
      'Failed to execute request because signed request is undefined'
    );
    return undefined;
  }
}
