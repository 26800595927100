import React, {useEffect, useState} from 'react';

import history from 'src/common/history';
import {getProjectId, getShieldId, SidebarItems} from 'src/common/constants';
import {useProjectDetailsData} from 'src/common/projectIdentityQuery';
import LayoutComponent from 'src/components/common/Layout/LayoutComponent';
import Row from '@amzn/meridian/row';
import ProjectDetails from 'src/components/ProjectDetails/ProjectDetails';
import {SideBarRowData} from 'src/common/dataType';

const ProjectsContainer = () => {
  const [project, setProjects] = useState(null);
  const projectId = getProjectId();
  const shieldId = getShieldId();

  const {isLoading, data} = useProjectDetailsData(projectId, shieldId);

  useEffect(() => {
    if (data) {
      setProjects(data.project);
    }
  }, [data]);

  const onClickBreadcrumb = (label: string) => {
    if (label === 'Projects') {
      history.push('/projects');
    }
  };

  let sidebarItemList: Array<SideBarRowData> = [];
  if (data?.project) {
    sidebarItemList = [
      {
        ...SidebarItems[0],
        label: data?.project?.name,
      },
    ];
  }

  return (
    <LayoutComponent
      onClickSidebar={(label: string) => console.log(label)}
      onClickHeader={() => {}}
      sidebarItems={sidebarItemList}
    >
      {isLoading ? (
        <Row
          className="loading-container"
          width="100%"
          widths="fill"
          spacingInset={'600 100'}
          alignmentHorizontal="center"
        >
          Loading...
        </Row>
      ) : (
        <ProjectDetails
          project={project}
          onClickBreadcrumb={onClickBreadcrumb}
        />
      )}
    </LayoutComponent>
  );
};

export default ProjectsContainer;
