import {getEnv} from '../constants';
export interface APIConfig {
  projectApiUrl: string;
  assumedProjectRoleArn: string;
  clientId: string;
  region: string;
}

const BETA_APP_CONFIG = {
  projectApiUrl: 'https://qx282ngvvg.execute-api.us-east-1.amazonaws.com',
  assumedProjectRoleArn:
    'arn:aws:iam::645469236959:role/AssumeRoleWithWebIdentity',
  clientId: 'https://beta.pi-ui.nazir.amazon.dev',
  region: 'us-east-1',
};

const GAMMA_APP_CONFIG = {
  projectApiUrl: 'https://wu9moq6im5.execute-api.us-east-1.amazonaws.com',
  assumedProjectRoleArn:
    'arn:aws:iam::575772265969:role/AssumeRoleWithWebIdentity',
  clientId: 'https://gamma.pi-ui.nazir.amazon.dev',
  region: 'us-east-1',
};

const APP_CONFIG: APIConfig =
  getEnv() === 'beta' ? {...BETA_APP_CONFIG} : {...GAMMA_APP_CONFIG};

export async function initAppConfig() {}

export default APP_CONFIG;
