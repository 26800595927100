// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumb, {BreadcrumbGroup} from '@amzn/meridian/breadcrumb';

const BreadcrumbComponent = ({items, onClickBreadcrumb}) => {
  return (
    <BreadcrumbGroup>
      {items.map(item => (
        <Breadcrumb href="#" onClick={() => onClickBreadcrumb(item)}>
          {item}
        </Breadcrumb>
      ))}
    </BreadcrumbGroup>
  );
};

BreadcrumbComponent.propTypes = {
  items: PropTypes.array,
  // onClickBreadcrumb: PropTypes.func,
};

export default BreadcrumbComponent;
