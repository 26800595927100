// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import AppLayout from '@amzn/meridian/app-layout';

import Sidebar from '../Sidebar/SidebarComponent';
import MastheadComponent from '../Masthead/MastheadComponent';
import './layoutComponent.scss';

const LayoutComponent = ({
  children,
  onClickSidebar,
  onClickHeader,
  sidebarItems,
}) => {
  const [showSidebar, setShowSidebar] = useState(true);
  return (
    <AppLayout
      headerComponent={MastheadComponent}
      sidebarComponent={Sidebar}
      backgroundColor="alternateSecondary"
    >
      <MastheadComponent
        onToggleSidebar={() => setShowSidebar(!showSidebar)}
        onClickIcon={label => onClickHeader(label)}
      />
      <div className="main-container">
        {showSidebar && (
          <Sidebar
            items={sidebarItems}
            onClickSidebar={label => onClickSidebar(label)}
          />
        )}
        {children}
      </div>
    </AppLayout>
  );
};

MastheadComponent.propTypes = {
  onClickSidebar: PropTypes.func,
  onClickHeader: PropTypes.func,
  sidebarItems: PropTypes.array,
  children: PropTypes.node,
};

export default LayoutComponent;
