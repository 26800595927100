// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react';
import PropTypes from 'prop-types';
import Masthead, {
  MastheadTitle,
  MastheadMenuButton,
} from '@amzn/meridian/masthead';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import bellToken from '@amzn/meridian-tokens/base/icon/bell';
import helpToken from '@amzn/meridian-tokens/base/icon/help-knockout';
import userToken from '@amzn/meridian-tokens/base/icon/user';
import Badge from '@amzn/meridian/badge';

import NazareLogo from 'src/images/nazare.png';
import './mastheadComponent.scss';

const MastheadComponent = ({onToggleSidebar, onClickIcon}) => {
  return (
    <div className="header-container">
      <Masthead>
        <MastheadMenuButton onClick={onToggleSidebar} open={true} />
        <MastheadTitle href="#" onClick={() => onClickIcon('logo')}>
          <img src={NazareLogo}></img>
        </MastheadTitle>
        <Button type="icon" onClick={() => onClickIcon('bell')}>
          <Icon tokens={bellToken}>
            <Badge value={9} />
            bell
          </Icon>
        </Button>
        <Button type="icon" onClick={() => onClickIcon('help')}>
          <Icon tokens={helpToken}>help</Icon>
        </Button>
        <Button type="icon" size="small" onClick={() => onClickIcon('user')}>
          <Icon tokens={userToken}>user</Icon>
        </Button>
      </Masthead>
    </div>
  );
};

MastheadComponent.propTypes = {
  onToggleSidebar: PropTypes.func,
  onClickIcon: PropTypes.func,
};

export default MastheadComponent;
