import React from 'react';
import ReactDOM from 'react-dom';
import {QueryClientProvider, QueryClient} from 'react-query';
import Loader from '@amzn/meridian/loader';

import AppRoutes from './Routes';
import AppInitWrapper from './components/AppInitWrapper';
import {initAppConfig} from './common/auth/appConfig';
import {initCredentials} from './common/auth/credentialsMethod';
import APP_CONFIG from 'src/common/auth/appConfig';
import history from 'src/common/history';
import './i18n';
import './logger';

import './index.scss';

// The Suspense component causes React to display the fallback KatSpinner until
// the i18n translation strings have been loaded.

const queryClient = new QueryClient();

function renderApp() {
  ReactDOM.render(
    <React.StrictMode>
      <AppInitWrapper>
        <React.Suspense fallback={<Loader size="large" />}>
          <QueryClientProvider client={queryClient}>
            <AppRoutes />
          </QueryClientProvider>
        </React.Suspense>
      </AppInitWrapper>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

initAppConfig().then(async () => {
  const creds = await initCredentials(
    APP_CONFIG.assumedProjectRoleArn,
    'PROJECT_IDENTITY'
  );
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get('id_token');
  if (myParam) {
    history.push(localStorage.getItem('redirect_url'));
  }
  if (creds !== null) {
    renderApp();
  }
});
